body > header {
  margin-top: 6rem;
  margin-bottom: 2.5rem;
  position: relative;
  min-height: 8.75rem;
  .espa {
    position: absolute;
    right: 0;
    top: -5.5rem;
    img {
      height: 4rem;
    }
    a {
      opacity: 1;
    }
  }
  .logo {
    padding-left: 0;
    padding-right: 0;
    // margin-left: -.1rem;
    img {
      height: 8rem;
      vertical-align: text-bottom;
    }
  }
  nav {
    margin: 1rem 0 0 1rem;
    .languages {
      // display: inline-table;
      .active {
        color: lightgray;
        border-bottom-color: transparent;
        cursor: default;
        &:hover, &:focus { opacity: .85; }
      }
    }
  }
  @media (min-width: 40rem) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
