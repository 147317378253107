.grid {
  margin: .5rem;
  div:not(.subgrid) { margin: 1rem 0; }
  @media (min-width: 60rem) {
    .weird1  { /*width: auto; height: 70%;*/ padding-bottom: 20%; padding-right: 30%; }
    .weird2 picture { width: 80%; height: 100%; }
    .weird3 picture { /*width: auto; height: 140%; margin-top: -40%;*/ padding-left: 20%; height: 130%; margin-top: -33%; }
  }
  @media (min-width: 50rem) {
    display: grid;
    grid-gap: .5rem;
    grid-template-rows: repeat(auto-fill, minmax( 5rem, 1fr ));
    grid-auto-rows: 5rem;
    .x2 { grid-row-end: span 2; }
    .x3 { grid-row-end: span 3; }
    .x4 { grid-row-end: span 4; }
    .x5 { grid-row-end: span 5; }
    .x6 { grid-row-end: span 6; }
    .x7 { grid-row-end: span 7; }
    .x8 { grid-row-end: span 8; }

    div:not(.subgrid) {
      // background: #111;
      @supports (display: flex) {
        display: flex;
        margin: 0;
      }
    }
    .subgrid {
      display: grid;
      grid-gap: .5rem;
      grid-auto-rows: minmax(5rem, 1fr);
      &.half {
        grid-template-columns: repeat(auto-fill, minmax( calc(50% - 1rem), 1fr ));
      }
      &.two-thirds {
        grid-template-columns: calc(70% - .25rem) calc(30% - .25rem);

        &.five-items {
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
          :nth-child(1) { grid-column: 1; grid-row: 1/4; }
          :nth-child(2) { grid-column: 1; grid-row: 4/7; }
          :nth-child(3) { grid-column: 2; grid-row: 1/3; }
          :nth-child(4) { grid-column: 2; grid-row: 3/5; }
          :nth-child(5) { grid-column: 2; grid-row: 5/7; }
        }
      }
      &.one-third {
        grid-template-columns: calc(30% - .25rem) calc(70% - .25rem);
        :first-child { grid-row-end: span 2; }
      }
    }
  }

  .border {
    border: 2px inset red;
    img { object-fit: initial; }
  }
  picture {
    display: flex;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  a {
    border: 0;
    padding: 0;
    display: inline;
    display: flex;
    position: relative;
    img {
      filter: grayscale(1);
      &:hover, &:focus {
        filter: grayscale(0);
      }
    }
    span {
      position: absolute;
      color: black;
      margin-left: 14px;
      margin-bottom: 8px;
      margin-top: 8px;
      font-size: 0.8em;
    }
    span.t {
      top: 0;

    }
    span.b {
      bottom: 0;
    }
  }
}
