.page-office {
  margin-top: 1rem;
  & > p {
    text-indent: 2rem;
  }

  blockquote {
    text-align: right;
    margin: 0 0 2.5rem;
    font-size: 1.2rem;
    font-style: italic;
    cite {
      font-style: normal;
      font-weight: normal;
      font-size: 1.1rem;
    }
  }

  .presentation {
    margin-top: 6.5rem;
    // max-width: 90%;
    img {
      margin: .5rem auto 2rem;
      display: block;
      max-width: 100%;
    }
    @media (min-width: 60rem) {
      display: flex;
      img {
        min-width: 280px;
        margin: .5rem auto auto;
        object-fit: cover;
        object-position: 85%;
      }
    }
  }

  .people {
    margin-left: 3.5rem;
  }

  h2 {
    font-size: 1.5rem;
    margin: 0 0 .1rem;
    &:before {
      content: '>';
      position: absolute;
      margin-left: -1.5rem;
    }
  }

  h2 + strong {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
    margin-bottom: -.25rem;
  }
}