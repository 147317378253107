.soon {
  text-align: center;
  margin: calc(30% + 235px) .5rem .5rem;

  .espa {
    // max-height: 165px;
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #1c1e1d;
    position: absolute;
    left: 0;
    top: 0;
    img {
      height: 150px;
      vertical-align: middle;
      opacity: .95;
    }
  }

  .text {
    font-family: PFPremierText;
    max-width: 600px;
    height: 6rem;
    display: inline-block;
    text-align: justify;
    text-align-last: justify;
    // line-height: 1.5em;
    font-size: 1rem;
  }

  .logo {
    background: url(../images/ADx2Logo.svg) no-repeat bottom left;
    background-size: contain;
    height: 6rem;
    width: 110px;
    display: inline-block;
    margin-right: 50px;
  }

  @media only screen and (max-width: 780px) {
    .logo { margin-right: 0; margin-bottom: 2rem; display: block; margin-left: auto; margin-right: auto;}
    .text { padding: 0 10px 0 10px;  height: auto; display: block; margin-left: auto; margin-right: auto;}
  }
}
