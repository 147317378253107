// @font-face {
//   font-family: "PFPremierText";
//   font-display: swap;
//   src: url("../fonts/PFPremierText-Regular.woff2") format('woff2'),
//        url("../fonts/PFPremierText-Regular.woff") format('woff');
// }

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans-bold.woff2") format('woff2'),
       url("../fonts/opensans-bold.woff") format('woff');
  font-weight: bold;
}
// @font-face {
//   font-family: "OpenSans";
//   src: url("../fonts/opensans-italic.woff2") format('woff2'),
//        url("../fonts/opensans-italic.woff") format('woff');
//   font-style: italic;
// }
@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans-regular.woff2") format('woff2'),
       url("../fonts/opensans-regular.woff") format('woff');
}

body {
  // transition: opacity .5s;
  opacity: 1;
  max-width: 50rem;
  min-width: 240px;
  width: 80%;
  margin: auto;
  background-color: #1c1e1d;
  color: white;
  // font-size: 20px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: OpenSans,-apple-system,BlinkMacSystemFont,"Segoe UI","Droid Sans",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

body > main {
  @supports (display: flex) {
    flex: 1;
  }
  margin-top: 2rem;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

h1 {
  font-size: 1.2rem;
}

a {
  display: inline-block;
  text-transform: uppercase;
  opacity: .85;
  // margin-left: 1rem;
  padding: .25rem;
  text-decoration: none;
  color: white;
  // font-size: .95rem;
  border-bottom: 2px solid transparent;
  transition: border-color .5s, color .25s, opacity .25s, filter 1s;
  &:hover, &:focus {
    opacity: 1;
    border-color: white;
  }
  &.active {
    border-bottom: 1px solid white;
  }
}

img {
  max-width: 100%;
  border: 0;
}

.no-break {
  white-space: nowrap;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
