body > footer {
  margin: 6rem 0 3rem;
  .logo {
    font-weight: normal;
    img {
      height: 6rem;
    }
    address {
      margin-bottom: 2.5rem;
      font-style: normal;
      color: #bbb;
      font-size: .95rem;
    }
  }
  @media (min-width: 55rem) {
    .credits { margin-left: 1rem; }
  }
  @media (min-width: 50rem) {
    .flex {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
  .copyright {
    display: inline-block;
    margin-bottom: .5rem;
    .by {
      display: inline-block;
      @media (min-width: 20rem) {
        &:not(:last-child):after {
          content: '|';
          margin: 0 .1rem;
        }
      }
    }
    a {
      text-transform: lowercase;
      margin: 0;
      font-size: 1.1rem;
    }
    b {
      margin-right: 1rem;
      margin-bottom: 0;
      // margin-top: .25rem;
      font-weight: normal;
      display: inline-block;
    }
    i {
      font-style: normal;
      text-transform: uppercase;
      font-size: .95rem;
      color: #bbb;
    }
    @media (min-width: 25rem) {
      margin-bottom: 0;
      .credits {
        display: inline-block;
      }
    }
  }
  // .sep {
  //   margin: 0 .5rem;
  // }
  .links {
    white-space: nowrap;
    // float: right;
    // text-align: right;
    // margin-top: .25rem;
    // margin-bottom: 2rem;
    // @media (min-width: 60rem) {
    //   margin-bottom: 0;
    // }
  }
  .links a { font-weight: bold; }
  
  .by span {
    text-transform: uppercase;
  }
  
  .by a { 
    font-weight: bold;
  }
  
}
