.page-contact {
  margin-top: 1rem;
  a {
    text-transform: lowercase;
    padding: 0 .25rem;
  }
  .contact-map {
    margin-top: 2rem;
    .leaflet-container a {
      font-size: 11px;
      color: black;
    }
  }
}