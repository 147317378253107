.page-projects {
  figure {
    width: 100%;
    margin: 0 0 4rem;
    img { 
      max-height: 50rem;
      display: block;
    }
    figcaption {
      display: inline-block;
      text-transform: uppercase;
      // font-size: 1.2rem;
    }
    @media (min-width: 60rem) {
      img { 
/*         max-height: 18rem;  */
/*         max-width: 100%;  */
        max-width: 49%;
        display: inline-block;
      }
      figcaption { /*margin: 0 0 0 4rem;*/ padding: 0.1em; padding-left: 0.5rem; display: inline-block; width: 49%; }
/*      &.fig01 img { max-height: 8rem; }
      &.fig03 img { max-height: 24rem; margin-left: 7rem; }
      &.fig07 img { max-height: 22rem; margin-left: 7rem; }
      &.fig05 img { max-height: 12.65rem; }
      &.fig06 img { max-height: 15rem; }*/
/*      &.fig02 img { max-height: 15rem; }
      &.fig04 img { max-height: 22rem; margin-left: 7rem; }
      &.fig05 img { max-height: 12.65rem; }
      &.fig06 img { max-height: 24rem; }
      &.fig07 img { max-height: 15rem; margin-left: 7rem; }
      &.fig08 img { max-height: 25rem; margin-left: 7rem; }
      &.fig09 img { max-height: 14.15rem; }*/
    }
  }
  
}
